import React from "react"
import { graphql } from "gatsby"
import CardsContainer from "@global/cards/CardsContainer"
import Seo from "@elements/Seo"
import Cta from "@global/cards/Cta"
import Banner from "@global/layout/Banner"
import Layout from "@layouts/layout"
import Block from "@global/layout/Block"
import { ProtectedRoute } from "../components/ProtectedRoute"
import Content from "@elements/Content"

const NutritionChats = ({ data, pageContext: { title, parent } }) => {
  const nutritionChatsHeading = data.miscContent.nodes[0].nutritionChatsHeading
  const nutritionChatsText = data.miscContent.nodes[0].nutritionChatsText

  return (
    <ProtectedRoute>
      <Layout title={title} parent={parent}>
        <Seo
          title={data.content.nutritionChats.meta.title}
          description={data.content.nutritionChats.meta.desc}
        />
        <div>
          {data.banner && <Banner image={data.banner.nutritionChatsBanner} />}
          <Block padding="double-top" gutters={true} maxWidth="inner">
            <h1>{nutritionChatsHeading}</h1>
            <Content html={nutritionChatsText} />
          </Block>
          <Block padding="both" gutters={true}>
            <CardsContainer modifier={"ctas"}>
              <Cta
                heading={"Eat Great"}
                subHeading={"DediKate"}
                image={data.banners.eatGreatBanner}
                link={{
                  title: "DediKate Eat Great",
                  path: "/nutrition-chats/dedikate-eat-great"
                }}
              />
              <Cta
                heading={"I Do"}
                subHeading={"DediKate"}
                image={data.banners.iDoBanner}
                link={{
                  title: "DediKate I Do",
                  path: "/nutrition-chats/dedikate-i-do"
                }}
              />
            </CardsContainer>
          </Block>
        </div>
      </Layout>
    </ProtectedRoute>
  )
}

export default NutritionChats

export const query = graphql`
  query NutritionChats {
    content: contentYaml(language: { eq: "en-NZ" }) {
      nutritionChats {
        content
        meta {
          title
          desc
        }
      }
    }
    banners: datoCmsGlobal {
      iDoBanner {
        gatsbyImageData(imgixParams: { fit: "crop", w: "664", h: "400" })
      }
      eatGreatBanner {
        gatsbyImageData(imgixParams: { fit: "crop", w: "664", h: "400" })
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
    banner: datoCmsGlobal {
      nutritionChatsBanner {
        gatsbyImageData(imgixParams: { w: "1280", h: "640", fit: "crop" })
      }
    }
    miscContent: allDatoCmsMiscContent {
      nodes {
        nutritionChatsHeading
        nutritionChatsText
      }
    }
  }
`
